import React from "react"
import { Layout, ILayoutProps } from "../components/Layout"
import { SEO } from "../components/Seo"
import { graphql, Link } from "gatsby";
import { MenuListing } from "../components/MenuListing";
import { IAppProps } from ".";

export const query = graphql`
query EatAllYouLikeMenuQuery {
    eatallyoulikeStartersJson {
        items {
            id
            name
            description
        }
    }
    eatallyoulikeSecondJson {
        items {
            id
            name
            description
        }
    }
    eatallyoulikeChickenJson {
        items {
            id
            name
            description
        }
    }
    eatallyoulikeBeefJson {
        items {
            id
            name
            description
        }
    }
    eatallyoulikeLambJson {
        items {
            id
            name
            description
        }
    }
    eatallyoulikePorkJson {
        items {
            id
            name
            description
        }
    }
    eatallyoulikeSeafoodJson {
        items {
            id
            name
            description
        }
    }
    eatallyoulikeVegetablesJson {
        items {
            id
            name
            description
        }
    }
    eatallyoulikeRiceNoodlesJson {
        items {
            id
            name
            description
        }
    }
    metaImage: file(relativePath: {eq: "meta.jpg"}) {
        childImageSharp {
        original{
            src
        }
        }
    }
}
`;

export default class EatAllYouLikeMenuPage extends React.Component<IAppProps, {}>
{
    constructor(props: IAppProps)
    {
        super(props);
    }

    public render(): JSX.Element
    {
        return (
            <Layout className="eat-all-you-like">
                <SEO title="Eat all you like Menu" image={this.props.data.metaImage.childImageSharp.original.src} />
                <section className="hero hero-sub-page">
                    <div className="hero-content text-center">
                        <div className="content content-right">
                            <h1>Eat All You Like <br /></h1>
                            <p className="lead">
                                With over 60 dishes to choose from including vegetarian options,
                                there's something for everyone.
                            </p>

                            <div className="button-group" style={{ width: "600px", maxWidth: "100%", margin: "0 auto" }}>
                                <Link to="/book" title="Book a table" className="btn btn-solid">
                                    Book a table
							    </Link>
                                <Link to="/menus" title="All menus" className="btn btn-solid">
                                    All menus
								</Link>
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <div className="content">
                        <div className="menu">
                            <div className="menu-feature">
                                <h1>Eat All You Like  <span>Mixed Hors D’Oeuvres</span></h1>
                            </div>
                            <MenuListing menuItems={this.props.data.eatallyoulikeStartersJson.items} />
                        </div>
                    </div>
                </section>

                <section>
                    <div className="content">
                        <div className="menu">
                            <div className="menu-feature">
                                <h1>Eat All You Like  <span>Second Course</span></h1>
                            </div>
                            <MenuListing menuItems={this.props.data.eatallyoulikeSecondJson.items} />
                        </div>
                    </div>
                </section>

                <section>
                    <div className="content">
                        <div className="menu">
                            <div className="menu-feature">
                                <h1>Eat All You Like  <span>Chicken</span></h1>
                            </div>
                            <MenuListing menuItems={this.props.data.eatallyoulikeChickenJson.items} />
                        </div>
                    </div>
                </section>

                <section>
                    <div className="content">
                        <div className="menu">
                            <div className="menu-feature">
                                <h1>Eat All You Like  <span>Beef</span></h1>
                            </div>
                            <MenuListing menuItems={this.props.data.eatallyoulikeBeefJson.items} />
                        </div>
                    </div>
                </section>

                <section>
                    <div className="content">
                        <div className="menu">
                            <div className="menu-feature">
                                <h1>Eat All You Like  <span>Lamb</span></h1>
                            </div>
                            <MenuListing menuItems={this.props.data.eatallyoulikeLambJson.items} />
                        </div>
                    </div>
                </section>

                <section>
                    <div className="content">
                        <div className="menu">
                            <div className="menu-feature">
                                <h1>Eat All You Like  <span>Pork</span></h1>
                            </div>
                            <MenuListing menuItems={this.props.data.eatallyoulikePorkJson.items} />
                        </div>
                    </div>
                </section>

                <section>
                    <div className="content">
                        <div className="menu">
                            <div className="menu-feature">
                                <h1>Eat All You Like  <span>Seafood</span></h1>
                            </div>
                            <MenuListing menuItems={this.props.data.eatallyoulikeSeafoodJson.items} />
                        </div>
                    </div>
                </section>

                <section>
                    <div className="content">
                        <div className="menu">
                            <div className="menu-feature">
                                <h1>Eat All You Like  <span>Vegetables</span></h1>
                            </div>
                            <MenuListing menuItems={this.props.data.eatallyoulikeVegetablesJson.items} />
                        </div>
                    </div>
                </section>

                <section>
                    <div className="content">
                        <div className="menu">
                            <div className="menu-feature">
                                <h1>Eat All You Like  <span>Rice and Noodles</span></h1>
                            </div>
                            <MenuListing menuItems={this.props.data.eatallyoulikeRiceNoodlesJson.items} />
                        </div>
                    </div>
                </section>
            </Layout>
        );
    }
}